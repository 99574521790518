/*------------------------------------
	Z index
------------------------------------*/
.z-index-5 {
	z-index: $z-index-5;
}
.z-index-10 {
	z-index: $z-index-10;
}
.z-index-20 {
	z-index: $z-index-20;
}
.z-index-50 {
	z-index: $z-index-50;
}
.z-index-100 {
	z-index: $z-index-100;
}
.z-index-500 {
	z-index: $z-index-500;
}
.z-index-1000 {
	z-index: $z-index-1000;
}