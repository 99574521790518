/*------------------------------------
	Dropdown submenu
------------------------------------*/
.navbar-nav {
	.dropdown-toggle::after {
		content: "\f107";
		font-family: $font-a-family;
		font-weight: $font-weight-900;
		margin-left: $font-a-margin-left;
		font-size: $font-a-size;
		vertical-align: inherit;
		border-top: inherit;
		border-right: inherit;
		border-bottom: 0;
		border-left: inherit;
	}
	.dropdown-menu {
		box-shadow: 0 0.4rem 2.2rem 0 rgba(27, 30, 36, 0.1);
		.dropdown-submenu, .menu-item-has-children {
			position: relative;
			.dropdown-menu {
				top: $dropdown-submenu-top;
				left: $dropdown-submenu-left;
				margin-left: $dropdown-submenu-margin-left;
				margin-right: $dropdown-submenu-margin-right;
			}
			a.dropdown-toggle::after, &>a::after{
				content: "\f105";
				font-family: $font-a-family;
				font-weight: $font-weight-900;
				position: absolute;
				right: $dropdown-toggle-after;
			}
			.dropdown-submenu .dropdown-menu {
				top: $dropdown-submenu-top;
			}
		}
	}
	.dropdown-item:hover {
		color: $dropdown-item-hover;
	}
	.nav-tabs{
		.nav-link{
			color: $dropdown-link-color;
		}
	}
}
.dropdown-menu {
	margin-top: 0;
}
.mini {
	font-size: $dropdown-submenu-mini;
}
@include media-breakpoint-down(md) {
	.navbar-expand-lg {
		.navbar-nav {
			.dropdown-menu {
				.dropdown-submenu {
					.dropdown-menu {
						margin-left: $dropdown-submenu-mobile-margin-left;
					}
					a.dropdown-toggle::after {
						content: "\f107";
						right: 0;
					}
				}
			}
			.dropdown-toggle::after {
				float: right;
				margin-right: $toggle-mobile-right-after;
				margin-left: $toggle-mobile-left-after;
			}
			.dropdown-menu.show {
				border-left-width: $dropdown-submenu-mobile-left;
				border-left-style: solid;
				border-left-color: $primary;
				border-top: 0;
			}
		}
	}
}
@include media-breakpoint-down(sm) {
	.navbar-expand-md {
		.navbar-nav {
			.dropdown-menu {
				.dropdown-submenu {
					.dropdown-menu {
						margin-left: $dropdown-submenu-mobile-margin-left;
					}
					a.dropdown-toggle::after {
						content: "\f107";
						right: 0;
					}
				}
			}
			.dropdown-toggle::after {
				float: right;
				margin-right: $toggle-mobile-right-after;
				margin-left: $toggle-mobile-left-after;
			}
			.dropdown-menu.show {
				border-left-width: $dropdown-submenu-mobile-left;
				border-left-style: solid;
				border-left-color: $primary;
				border-top: 0;
			}
		}
	}
}
@include media-breakpoint-up(sm) {
	.border-right-sm {
		border-right: $border-right-sm solid $border-color;
	}
}
@include media-breakpoint-up(lg) {
	.hover-mode .main-nav {
		.dropdown-menu {
			&.show {
				display: none;
			}
			&.mega.show {
				display: none;
			}
		}
		li:hover {
			&> a {
				color: $primary;
				background-color: $light;
			}
			&> .dropdown-menu {
				display: block;
				&.show {
					display: block;
				}
				&.mega.show {
					display: block;
				}
			}
		}
		>li:hover {
			&> a {
				background-color: transparent;
			}
		}
	}
	.navbar-dark {
		.hover-mode{
			li:hover {
				&> a {
					color: $primary;
					background-color: $dark;
				}
			}
		}
	}
	.navbar-light .hover-mode .main-nav {
		.show > .nav-link {
			color: $light-nav-hover-link;
		}
		li.show:hover > .nav-link {
			color: $primary;
		}
	}
	.navbar-expand-lg .main-nav {
		.nav-link {
			padding-right: $mobile-navlink-right;
			padding-left: $mobile-navlink-left;
		}
	}
	.first-left-lg-0 {
		&>li:first-child>.nav-link {
			padding-right: $mobile-navlink-right;
			padding-left: $mobile-navlink-left2;
		}
	}
}
.ticker {
	width: 100%;
	margin: $ticker-margin auto;
	div {
		display: inline-block;
		word-wrap: break-word;
	}
}
.social-fly {
	top: $social-fly-top;
	padding-top: $social-fly-padding-top;
	position: fixed;
	left: $social-fly-left;
	ul li {
		margin-bottom: $social-fly-bottom;
	}
}
@include media-breakpoint-up(xl) {
	.social-fly {
		left: $social-fly-lg-left;
	}
}
.navbar-light {
	.nav-item:hover{
		&>.nav-link {
			color: $dropdown-item-hover;
		}
	}
}
.dropright .no-caret::after {
	border: none;
	margin: 0;
}
.dropleft .no-caret::after {
	border: none;
	margin: 0;
}
.dropup .no-caret::after {
	border: none;
	margin: 0;
}