/*------------------------------------
	Google Fonts
------------------------------------*/
.font-family {
	h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
		font-family: $font_3, Helvetica, $font_4;
	}
	.navbar-nav {
		font-family: $font_3, Helvetica, $font_4;
	}
}