/*------------------------------------
	Widget Social
------------------------------------*/
.social-two, .social-one {
	li {
		float: left;
		width: $social2-width-li;
		&:nth-child(even) {
			float: right;
		}
		a {
			display: block;
			font-size: $social2-li-a-font;
			line-height: $social2-li-a-height;
			border-radius: $social2-li-a-radius;
			&:hover {
				opacity: $opacity-09;
			}
			&:focus {
				opacity: $opacity-09;
			}
		}
		a::before {
			float: left;
			font-family: $font-a-brand-family;
			font-weight: $font-weight-normal;
			font-size: $font-a-size;
			margin-left: $font-a-margin-left;
			vertical-align: inherit;
			border-top: inherit;
			border-right: inherit;
			border-bottom: 0;
			border-left: inherit;
			width: $social-a-before-width;
			height: $social-a-before-height;
			font-size: $social-a-before-font;
			text-align: center;
			background-color: $social-a-before-bg;
			margin: $social-a-before-margin;
		}
	}
	.facebook a::before {
		content: "\f082";
	}
	.twitter a::before {
		content: "\f099";
	}
	.vk a::before {
		content: "\f189";
	}
	.instagram a::before {
		content: "\f16d";
	}
	.youtube a::before {
		content: "\f167";
	}
	.linkedin a::before {
		content: "\f08c";
	}
	.vimeo a::before {
		content: "\f40a";
	}
	.pinterest a::before {
		content: "\f0d2";
	}
	.telegram a::before {
		content: "\f2c6";
	}
	.envelope a::before {
		content: "\f0e0";
		font-family: $font-a-family;
		font-weight: $font-weight-900;
	}
}
.social-three {
	li{
		margin: 0 -1px -1px 0 !important;
		&:hover {
			opacity: $opacity-09;
		}
		&:focus {
			opacity: $opacity-09;
		}
	}
}
.btn-social {
	&:hover {
		opacity: $opacity-09;
	}
	&:focus {
		opacity: $opacity-09;
	}
}
@include media-breakpoint-up(md) {
	.social-two, .social-one {
		li {
			width: $social2-width-li-md;
		}
	}
}
@include media-breakpoint-up(lg) {
	.social-two {
		li {
			width: $social2-width-li;
		}
	}
}
/*------------------------------------
  Social color
------------------------------------*/
@each $color, $value in $socialcolors {
	.text-#{$color} {
		color: $value !important;
	}
	.hover-#{$color}-text {
		&:hover {
			i {
                color: $value !important;
			}
		}
	}
	.hover-#{$color} {
		a {
			&:hover {
				i {
                    color: $value !important;
				}
			}
		}
	}
	.bg-#{$color}, .btn-#{$color} {
		background-color: $value !important;
		color: $white;
	}
}