/*------------------------------------
  Boxed and Framed Layout
------------------------------------*/
//@extend-elements
//.boxed .wrapper, .framed .wrapper
%extend_layout {
	position: relative;
	z-index: 1;
	margin: 0 auto;
	box-shadow: $boxed-framed-box-shadow;
}
.full-width {
	background-color: $white;
}
.boxed {
	background-color: $boxed-framed-bg;
	.wrapper {
		background-color: $boxed-wrapper-bg;
		@extend %extend_layout;
	}
}
.framed {
	background-color: $boxed-framed-bg;
	.wrapper {
		@extend %extend_layout;
	}
	main, .second-header , .bg-nav-framed{
		background-color: $framed-main-bg;
	}
}
@include media-breakpoint-down(sm) {
	.boxed .wrapper, .framed .wrapper {
		width: $boxed-framed-mobile-width;
	}
}
@include media-breakpoint-up(md) {
	.boxed .wrapper, .framed .wrapper {
		width: $boxed-framed-tabs-width;
	}
	.framed{
		padding: 15px 0;
	}
}
@include media-breakpoint-up(xl) {
	.boxed, .framed{
		.wrapper {
			width: $boxed-framed-desktop-width;
		}
		.navbar {
			padding: $boxed-framed-desktop-padding;
		}
	}
}