/*------------------------------------
 Font weight
------------------------------------*/
.font-weight-500 {
  font-weight: $font-weight-500;
}
.font-weight-600 {
  font-weight: $font-weight-600;
}
.font-weight-800 {
  font-weight: $font-weight-800;
}
.font-weight-900 {
  font-weight: $font-weight-900;
}
// font size
.font-size-1 {
  font-size: $font-size-1;
}
.font-size-2 {
  font-size: $font-size-2;
}
.font-size-3 {
  font-size: $font-size-3;
}
.font-size-4 {
  font-size: $font-size-4;
}
.font-size-5 {
  font-size: $font-size-5;
}