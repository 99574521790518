/*------------------------------------
   Breadcrumb
------------------------------------*/
.u-breadcrumb {
	color:$breadcrumb-active-color;
	background: transparent;
	font-size: 90%;
	a{
		color:$breadcrumb-active-color;
	}
	.breadcrumb-item {
		font-family: $font_3, $font_4;
		a{
			color: $breadcrumb-active-color;
		}
	}
	.breadcrumb-item::before {
		display: inline-block;
		padding-right: $breadcrumb-padding-right-1;
		color: $breadcrumb-active-color;
		content: "»";
	}
	.breadcrumb-item:first-child::before {
		display: none;
	}
}