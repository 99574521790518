/*------------------------------------
	Big Grid
------------------------------------*/
// featured 1
.featured-1 {
	.ratio {
		padding-bottom: 84.888%;
		height: 0;
	}
	.newsbox {
		.ratio {
			padding-bottom: 84.777%;
		}
		> article:nth-child(1) { 
			padding-top: 0;
			padding-bottom: $ratio-padding-05;
			padding-right: $ratio-padding-05;
		}
		> article:nth-child(2) {
			padding-top: 0;
			padding-bottom: $ratio-padding-05;
			padding-left: $ratio-padding-05;
		}
		> article:nth-child(3) {
			padding-top: $ratio-padding-05;
			padding-bottom: $ratio-padding-05;
			padding-right: $ratio-padding-05;
		}
		> article:nth-child(4) {
			padding-top: $ratio-padding-05;
			padding-bottom: $ratio-padding-05;
			padding-left: $ratio-padding-05;
		}
	}
}
// featured 2
.featured-2 {
	.ratio {
		padding-bottom: 85.2%;
		height: 0;
	}
	.newsbox {
		.ratio {
			padding-bottom: 84.77777%;
		}
		> article:nth-child(1) { 
			padding-top: 0;
			padding-bottom: $ratio-padding-05;
			-ms-flex: 0 0 100%;
			flex: 0 0 100%;
			max-width: 100%;
			.ratio {
				padding-bottom: 42.4%;
			}
		}
		> article:nth-child(2) {
			padding-top: $ratio-padding-05;
			padding-right: $ratio-padding-05;
			padding-bottom: $ratio-padding-05;
		}
		> article:nth-child(3) {
			padding-top: $ratio-padding-05;
			padding-left: $ratio-padding-05;
			padding-bottom: $ratio-padding-05;
		}
	}
}
// featured 3
.featured-3 {
	.ratio {
		padding-bottom: 55.3555556%;
		height: 0;
	}
	.newsbox {
		.ratio {
			padding-bottom: 56.0085837%;
		}
		> article:nth-child(1) { 
			padding-bottom: $ratio-padding-05;
		}
		> article:nth-child(2) {
			padding-top: $ratio-padding-05;
		}
	}
}
// featured 4
.featured-4 {
	.ratio {
		padding-bottom: 84.55555%;
		height: 0;
	}
	.newsbox {
		.ratio {
			padding-bottom: 84.77777%;
		}
		> article:nth-child(1) { 
			padding-top: 0;
			padding-bottom: $ratio-padding-05;
			padding-right: $ratio-padding-05;
		}
		> article:nth-child(2) {
			padding-top: 0;
			padding-left: $ratio-padding-05;
			padding-bottom: $ratio-padding-05;
		}
		> article:nth-child(3) {
			padding-top: $ratio-padding-05;
			-ms-flex: 0 0 100%;
			flex: 0 0 100%;
			max-width: 100%;
			.ratio {
				padding-bottom: 41.89%;
			}
		}
	}
}
// featured 5
.featured-5 {
	.newsbox-left {
		.ratio {
			padding-bottom: 84.77777%;
			height: 0;
		}
		> article:nth-child(1) { 
			padding-top: 0;
			padding-bottom: $ratio-padding-05;
			-ms-flex: 0 0 100%;
			flex: 0 0 100%;
			max-width: 100%;
			.ratio {
				padding-bottom: 42.15%;
			}
		}
		> article:nth-child(2) {
			padding-top: $ratio-padding-05;
			padding-right: $ratio-padding-05;
			padding-bottom: $ratio-padding-05;
		}
		> article:nth-child(3) {
			padding-top: $ratio-padding-05;
			padding-left: $ratio-padding-05;
			padding-bottom: $ratio-padding-05;
		}
	}
	.newsbox-right {
		.ratio {
			padding-bottom: 84.777%;
			height: 0;
		}
		> article:nth-child(1) { 
			padding-top: 0;
			padding-bottom: $ratio-padding-05;
			padding-right: $ratio-padding-05;
		}
		> article:nth-child(2) {
			padding-top: 0;
			padding-bottom: $ratio-padding-05;
			padding-left: $ratio-padding-05;
		}
		> article:nth-child(3) {
			padding-top: $ratio-padding-05;
			padding-bottom: $ratio-padding-05;
			padding-right: $ratio-padding-05;
		}
		> article:nth-child(4) {
			padding-top: $ratio-padding-05;
			padding-bottom: $ratio-padding-05;
			padding-left: $ratio-padding-05;
		}
	}
}
// featured 6
.featured-6 {
	.newsbox {
		.ratio {
			padding-bottom: 99.999%;
			height: 0;
		}
		> article:nth-child(1) { 
			padding-top: $ratio-padding-05;
			padding-bottom: $ratio-padding-05;
			padding-right: 0;
			padding-left: 0;
		}
		> article:nth-child(2) {
			padding-top: $ratio-padding-05;
			padding-left: 0;
			padding-right: $ratio-padding-05;
		}
		> article:nth-child(3) {
			padding-top: $ratio-padding-05;
			padding-right: 0;
			padding-left: $ratio-padding-05;
		}
	}
}
// featured 7
.featured-7 {
	.ratio {
		padding-bottom: 64.22%;
		height: 0;
	}
	.newsbox {
		.ratio {
			padding-bottom: 43%;
		}
		> article:nth-child(1) { 
			padding-top: 0;
			padding-bottom: $ratio-padding-05;
		}
		> article:nth-child(2) {
			padding-top: $ratio-padding-05;
			padding-bottom: $ratio-padding-05;
		}
		> article:nth-child(3) {
			padding-top: $ratio-padding-05;
		}
	}
}
// featured 8
.featured-8 {
	.newsbox-left {
		.ratio {
			padding-bottom: 85%;
			height: 0;
		}
		> article:nth-child(1) { 
			padding-top: $ratio-padding-05;
			padding-bottom: $ratio-padding-05;
			padding-left: 0;
			padding-right: $ratio-padding-05;
		}
		> article:nth-child(2) {
			padding-top: $ratio-padding-05;
			padding-bottom: $ratio-padding-05;
			padding-left: $ratio-padding-05;
			padding-right: 0;
		}
	}
	.newsbox-center {
		.ratio {
			padding-bottom: 85.6%;
			height: 0;
		}
		> article:nth-child(1) { 
			padding-top: $ratio-padding-05;
			padding-bottom: $ratio-padding-05;
			padding-left: 0;
			padding-right: 0;
		}
	}
	.newsbox-right {
		.ratio {
			padding-bottom: 85%;
			height: 0;
		}
		> article:nth-child(1) { 
			padding-top: $ratio-padding-05;
			padding-bottom: $ratio-padding-05;
			padding-left: 0;
			padding-right: $ratio-padding-05;
		}
		> article:nth-child(2) {
			padding-top: $ratio-padding-05;
			padding-bottom: $ratio-padding-05;
			padding-left: $ratio-padding-05;
			padding-right: 0;
		}
	}
}
// featured 9
.featured-9 {
	.newsbox-left, .newsbox-right {
		.ratio {
			padding-bottom: 84.77777%;
			height: 0;
		}
		> article:nth-child(1) { 
			padding-top: 0;
			padding-bottom: $ratio-padding-05;
			-ms-flex: 0 0 100%;
			flex: 0 0 100%;
			max-width: 100%;
			.ratio {
				padding-bottom: 41.855555%;
			}
		}
		> article:nth-child(2) {
			padding-top: $ratio-padding-05;
			padding-right: $ratio-padding-05;
			padding-bottom: $ratio-padding-05;
		}
		> article:nth-child(3) {
			padding-top: $ratio-padding-05;
			padding-left: $ratio-padding-05;
			padding-bottom: $ratio-padding-05;
		}
	}
}
// featured 10
.featured-10 {
	.newsbox-left {
		.ratio {
			padding-bottom: 84.77777%;
			height: 0;
		}
		> article:nth-child(1) { 
			padding-top: 0;
			padding-bottom: $ratio-padding-05;
			-ms-flex: 0 0 100%;
			flex: 0 0 100%;
			max-width: 100%;
			.ratio {
				padding-bottom: 41.855555%;
			}
		}
		> article:nth-child(2) {
			padding-top: $ratio-padding-05;
			padding-right: $ratio-padding-05;
			padding-bottom: $ratio-padding-05;
		}
		> article:nth-child(3) {
			padding-top: $ratio-padding-05;
			padding-left: $ratio-padding-05;
			padding-bottom: $ratio-padding-05;
		}
	}
	.newsbox-right {
		.ratio {
			padding-bottom: 84.77777%;
			height: 0;
		}
		> article:nth-child(1) { 
			padding-top: 0;
			padding-bottom: $ratio-padding-05;
			padding-right: $ratio-padding-05;
		}
		> article:nth-child(2) {
			padding-top: 0;
			padding-left: $ratio-padding-05;
			padding-bottom: $ratio-padding-05;
		}
		> article:nth-child(3) {
			padding-top: $ratio-padding-05;
			-ms-flex: 0 0 100%;
			flex: 0 0 100%;
			max-width: 100%;
			.ratio {
				padding-bottom: 41.89%;
			}
		}
	}
}
@include media-breakpoint-down(md) {
	.featured-2 {
		.ratio {
			padding-bottom: 50%;
		}
		.newsbox {
			.ratio {
				padding-bottom: 84.77777%;
			}
			> article:nth-child(1) {
				.ratio {
					padding-bottom: 41.855555%;
				}
			}
		}
	}
	.featured-4 {
		.ratio {
			padding-bottom: 50%;
		}
		.newsbox {
			.ratio {
				padding-bottom: 84.77777%;
			}
		}
	}
}
@include media-breakpoint-down(sm) {
	.featured-6{ 
		.newsbox {
			> article:nth-child(1) {
				-ms-flex: 0 0 100%;
				flex: 0 0 100%;
				max-width: 100%;
			}
		}
	}
}
@include media-breakpoint-up(sm) {
	.featured-3 {
		.newsbox {
			> article:nth-child(1) { 
				padding-top: 0;
				padding-right: $ratio-padding-05;
			}
			> article:nth-child(2) {
				padding-top: 0;
				padding-left: $ratio-padding-05;
			}
		}
	}
	.featured-7{ 
		.newsbox {
			> article:nth-child(1) {
				padding-right: $ratio-padding-05;
				padding-bottom: $ratio-padding-05;
			}
			> article:nth-child(2) {
				padding-top: 0;
				padding-left: $ratio-padding-05;
				padding-bottom: $ratio-padding-05;
			}
			> article:nth-child(3) {
				padding-right: $ratio-padding-05;
				padding-bottom: $ratio-padding-05;
			}
		}
	}
}

@include media-breakpoint-up(md) {
	.featured-6 {
		.newsbox {
			> article:nth-child(1) {
				padding-right: .1875rem;
			}
			> article:nth-child(2) {
				padding-left: .09375rem;
				padding-right: .09375rem;
			}
			> article:nth-child(3) {
				padding-left: .1875rem;
			}
		}
	}
	.featured-7 {
		.ratio {
			padding-bottom: 64.22%;
		}
		.newsbox {
			.ratio {
				padding-bottom: 43%;
			}
			> article:nth-child(1) {
				padding-right: $ratio-padding-15;
				padding-left: $ratio-padding-15;
				padding-bottom: $ratio-padding-05;
			}
			> article:nth-child(2) {
				padding-top: $ratio-padding-05;
				padding-right: $ratio-padding-15;
				padding-left: $ratio-padding-15;
				padding-bottom: $ratio-padding-05;
			}
			> article:nth-child(3) {
				padding-top: $ratio-padding-05;
				padding-right: $ratio-padding-15;
				padding-left: $ratio-padding-15;
				padding-bottom: $ratio-padding-05;
			}
		}
	}
}
@include media-breakpoint-up(lg) {
	.featured-3 {
		.newsbox {
			> article:nth-child(1) { 
				padding-top: 0;
				padding-left: $ratio-padding-1;
				padding-right: $ratio-padding-15;
			}
			> article:nth-child(2) {
				padding-top: $ratio-padding-05;
				padding-left: $ratio-padding-1;
				padding-right: $ratio-padding-15;
			}
		}
	}
	.featured-8 {
		.newsbox-left {
			> article:nth-child(2) {
				padding-left: 0;
				padding-right: $ratio-padding-05;
			}
		}
		.newsbox-center {
			> article:nth-child(1) {
				padding-left: $ratio-padding-05;
				padding-right: $ratio-padding-05;
			}
		}
		.newsbox-right {
			> article:nth-child(1), > article:nth-child(2) {
				padding-left: $ratio-padding-05;
				padding-right: 0;
			}
		}
	}
}